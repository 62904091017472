import {useEffect, useState} from 'react'
import {FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {ReactComponent as Arrow} from '../../../../assets/icons/arrow-prev.svg'
import Button from '../../../../components/Button/Button'
import TextInput from '../../../../components/TextInput/TextInput'
import {PATHS} from '../../../../constants/paths'
import * as dniForm from '../../../../constants/personalInformationForm'
import {IDniForm} from '../../../../constants/personalInformationForm'
import {customerApi} from '../../../../services'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {usePersonFormsSlice} from '../../../../store/slices/personForms'

import styles from '../InvestorInfo.module.scss'

const DniForm = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {nextForm} = usePersonFormsSlice()
  const {
    customer_info: {id, first_name, last_name, identification_number, birth_date},
    setCustomerData,
  } = useCustomerSlice()
  const methods = useForm<IDniForm>({
    defaultValues: {
      name: first_name || '',
      surname: last_name || '',
      dni: identification_number || '',
      birthdate: birth_date || '',
    },
  })
  const [submitting, setSubmitting] = useState<boolean>(false)
  const onSubmit = async (data: IDniForm) => {
    try {
      setSubmitting(true)
      const responseData = await customerApi.modifyCustomerInfo(id || 0, {
        person_type: 'NATURAL',
        first_name: data.name,
        last_name: data.surname,
        identification_number: data.dni,
        birth_date: data.birthdate,
      })
      setCustomerData({customer_info: responseData})
      nextForm()
    } catch (error: any) {
      console.log('Error on put data', error)
    } finally {
      setSubmitting(false)
    }
  }
  const onSubmitError = (errorData: SubmitErrorHandler<IDniForm> | FieldErrors<IDniForm>) => {
    console.log('PIForm-ErrorData', errorData)
  }

  useEffect(() => {
    methods.reset({
      name: first_name,
      surname: last_name,
      birthdate: birth_date,
      dni: identification_number,
    })
  }, [first_name, last_name, birth_date, identification_number, methods])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {<Arrow className={styles.prev} onClick={() => navigate(PATHS.PERSONAL_INFORMATION)} />}
        <span className={styles.title}>{t('personalInformation.title')}</span>
        <span className={styles.clarification}>
          {t('personalInformation.person.dniForm.clarification')}
        </span>
      </div>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
          <TextInput
            label={`${t(`user.${dniForm.NAME}`)}`}
            name={dniForm.NAME}
            withUseForm
            underlined
            required
          />
          <TextInput
            label={`${t(`user.${dniForm.SURNAME}`)}`}
            name={dniForm.SURNAME}
            withUseForm
            underlined
            required
          />
          <TextInput
            label={`${t(`user.${dniForm.DNI}`)}`}
            name={dniForm.DNI}
            withUseForm
            underlined
            required
          />
          <TextInput
            label={`${t(`user.${dniForm.BIRTHDATE}`)}`}
            name={dniForm.BIRTHDATE}
            type="date"
            withUseForm
            underlined
            required
          />

          <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            type="submit"
            // disabled={!methods.formState.isValid}
            loading={submitting}>
            {t('continue')}
          </Button>
        </form>
      </FormProvider>
    </div>
  )
}

export default withTranslation()(DniForm)
