import {useState} from 'react'
import {FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'
import copyToClipboard from 'copy-to-clipboard'

import {ReactComponent as BrandLogo} from '../../../../assets/brandLogos/brand-logo-mobile.svg'
import {ReactComponent as Arrow} from '../../../../assets/icons/arrow-prev.svg'
import {ReactComponent as Copy} from '../../../../assets/icons/copy.svg'
import Button from '../../../../components/Button/Button'
import Checkbox from '../../../../components/Checkbox/Checkbox'
import Modal from '../../../../components/Modal/Modal'
import {PATHS} from '../../../../constants/paths'
import * as regulationsForm from '../../../../constants/personalInformationForm'
import {IRegulationsForm} from '../../../../constants/personalInformationForm'
import {useUser} from '../../../../contexts/UserContext'
import {customerApi} from '../../../../services'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {usePersonFormsSlice} from '../../../../store/slices/personForms'

import styles from '../InvestorInfo.module.scss'

const PersonInfo = ({t}: WithTranslation) => {
  const {user} = useUser()
  const navigate = useNavigate()
  const {
    customer_info: {id},
    setCustomerData,
  } = useCustomerSlice()
  const {lastForm} = usePersonFormsSlice()
  const methods = useForm<IRegulationsForm>({
    defaultValues: {
      notPoliticallyExposed: false,
      notUsResident: false,
      noneOfTheAbove: false,
    },
  })
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [copiedVisible, showCopied] = useState<boolean>(false)

  const [notPoliticallyExposedChecked, notUsResidentChecked, noneOfTheAbove] = methods.watch([
    'notPoliticallyExposed',
    'notUsResident',
    'noneOfTheAbove',
  ])

  const onSubmit = async (data: IRegulationsForm) => {
    if (data.notPoliticallyExposed || data.notUsResident) return
    const responseData = await customerApi.modifyCustomerInfo(id || 0, {onboarding_completed: true})
    setCustomerData({customer_info: responseData})
    setModalVisible(true)
  }
  const onSubmitError = (
    errorData: SubmitErrorHandler<IRegulationsForm> | FieldErrors<IRegulationsForm>,
  ) => {
    console.log('PIForm-ErrorData', errorData)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          {<Arrow className={styles.prev} onClick={() => lastForm()} />}
          <span className={classNames(styles.title, styles.last)}>
            {t('personalInformation.person.regulationsForm.title')}
          </span>
        </div>
        <FormProvider {...methods}>
          <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
            <div className={styles.checkboxContainer}>
              <Checkbox
                classNameContainer={styles.checkbox}
                filledColor="secondary-light"
                name={regulationsForm.POLITICALLY_EXPOSED_CHECKBOX}
                withUseForm
              />
              <div className={styles.textWrapper}>
                <span className={styles.label}>{`${t(
                  `user.${regulationsForm.POLITICALLY_EXPOSED_CHECKBOX}`,
                )}`}</span>
                <span className={styles.description}>{`${t(
                  `user.${regulationsForm.POLITICALLY_EXPOSED_CHECKBOX}Description`,
                )}`}</span>
                <a
                  href="https://www.argentina.gob.ar/uif/preguntas-frecuentes-generales-uif/personas-expuestas-politicamente"
                  target="_blank"
                  rel="noopener noreferrer">
                  {t('user.learnMore')}
                </a>
              </div>
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox
                classNameContainer={styles.checkbox}
                filledColor="secondary-light"
                name={regulationsForm.US_RESIDENT_CHECKBOX}
                withUseForm
              />
              <div className={styles.textWrapper}>
                <span className={styles.label}>{`${t(
                  `user.${regulationsForm.US_RESIDENT_CHECKBOX}`,
                )}`}</span>
                <span className={styles.description}>{`${t(
                  `user.${regulationsForm.US_RESIDENT_CHECKBOX}Description`,
                )}`}</span>
                <a
                  href="https://www.argentina.gob.ar/uif/normativa-uif/resoluciones-aplicables-cada-sujeto-obligado"
                  target="_blank"
                  rel="noopener noreferrer">
                  {t('user.learnMore')}
                </a>
              </div>
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox
                classNameContainer={styles.checkbox}
                filledColor="secondary-light"
                name={regulationsForm.NONE_OF_THE_ABOVE}
                withUseForm
              />
              <div className={styles.textWrapper}>
                <span className={styles.label}>
                  {`${t(`user.${regulationsForm.NONE_OF_THE_ABOVE}`)}`}
                </span>
              </div>
            </div>
            <Button
              className={styles.button}
              styledType="filled"
              filledColor="primary"
              type="submit"
              disabled={
                !methods.formState.isValid ||
                notPoliticallyExposedChecked ||
                notUsResidentChecked ||
                !noneOfTheAbove
              }>
              {t('confirm')}
            </Button>
          </form>
        </FormProvider>
      </div>

      <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        closeButtonHidden
        outerClassName={styles.successOuterModal}
        containerClassName={styles.containerModal}
        button={{
          label: t('goToPortfolio'),
          onClick: () => {
            navigate(PATHS.PORTFOLIO)
          },
        }}>
        <div className={styles.text}>
          <span
            className={classNames(styles.copied, {
              [styles.show]: copiedVisible,
            })}>
            {t('walletCopiedSuccesffully')}
          </span>
          <BrandLogo className={styles.brandLogo} />
          <span className={styles.title}>{t('youArePartOf')}</span>
          <span className={styles.wallet}>{t('yourWalletIs')}:</span>
          <span
            className={styles.address}
            onClick={() => {
              if (!user) return
              showCopied(true)
              copyToClipboard(user)
              setTimeout(() => {
                showCopied(false)
              }, 2500)
            }}>
            {(!!user &&
              `${user?.substring(0, 6)}...${user?.substring(user?.length - 6, user?.length)}`) || (
              <Skeleton width={150} />
            )}
            <Copy />
          </span>
        </div>
      </Modal>
    </>
  )
}

export default withTranslation()(PersonInfo)
