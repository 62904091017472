import {PaymentMeanCurrencyType} from '../../constants/currencies'
import axios from '../../core/axios'
import {BRAND, BRAND_KEY} from '../../utils/brand'

export enum ACTION_TYPE {
  fund = 'fund',
  buy = 'buy',
  withdraw = 'withdraw',
  sell = 'sell',
  reserve = 'reserve',
}
export type ActionType = keyof typeof ACTION_TYPE

export interface IActivity {
  id: number
  created_at: string
  modified_at: string
  action: ActionType
  is_viewed: boolean
  amount: string
  currency: PaymentMeanCurrencyType
  description: string
  customer: number
  project_name: string
  wallet: string
}

export interface IActivityData {
  count: number
  next: number
  previous: number
  results: IActivity[]
}

interface IActivityParams {
  page?: number
  action?: ActionType
  ordering?: string
}

export default {
  getAllActivity: async (params?: IActivityParams): Promise<IActivityData> => {
    try {
      const {data: responseData} = await axios.get(
        'activity/all/?' +
          `${BRAND === BRAND_KEY.criptokuantica ? '' : 'brand=' + BRAND}&` +
          ((params?.ordering && 'ordering=' + params?.ordering) || '') +
          ((params?.page && '&page=' + params?.page) || '') +
          ((params?.action && '&action=' + params?.action) || ''),
      )
      return responseData as IActivityData
    } catch (error: any) {
      throw Error(error?.message)
    }
  },
}
