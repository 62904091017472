import {useCallback, useEffect} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

import Button from '../../../../../../components/Button/Button'
import {currentCurrency} from '../../../../../../constants/currencies'
import {PATHS} from '../../../../../../constants/paths'
import {projectApi} from '../../../../../../services'
import {
  clearInvestmentStorage,
  getInvestmentStorage,
} from '../../../../../../store/localStorage/investment'
import {TOKEN_AMOUNT_INDEX, useCounterSlice} from '../../../../../../store/slices/counter'
import {useProjectSlice} from '../../../../../../store/slices/project'
import {useStepperSlice} from '../../../../../../store/slices/stepper'
import {formatNumber} from '../../../../../../utils/formatNumber'

import styles from './SuccessfulPayment.module.scss'

const SuccessfulPayment = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {itemId} = useParams()
  const {quantity} = useCounterSlice()
  const {reset} = useStepperSlice()
  const {
    id: projectId,
    name,
    tokenomic: {
      token: {id: tokenId, price, currency},
    },
    setProject,
  } = useProjectSlice()

  const getProject = useCallback(
    async (id: number) => {
      const project = await projectApi.getProject(id)
      setProject(id, project)
    },
    [itemId, projectId, setProject],
  )

  useEffect(() => {
    if (!projectId) !!itemId && getProject(+itemId)
  }, [itemId, projectId])

  const amountOfTokens =
    getInvestmentStorage()?.amountOfTokens || quantity[`${TOKEN_AMOUNT_INDEX}${tokenId}`] || 1

  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>{t('investment.successfulPayment.title')}</div>
        <span className={styles.bought}>{t('investment.successfulPayment.bought')}</span>
      </div>
      <div className={styles.body}>
        <div className={styles.projectData}>
          <span>{amountOfTokens}</span>
          {name}
        </div>
        <div className={styles.line} />
        <span className={styles.price}>
          {formatNumber(amountOfTokens * +price)} {currency || currentCurrency}
        </span>
      </div>
      <Button
        className={styles.button}
        styledType="filled"
        filledColor="primary"
        onClick={() => {
          clearInvestmentStorage()
          reset()
          navigate(PATHS.PORTFOLIO)
        }}>
        {t('investment.successfulPayment.seeOnMyProfile')}
      </Button>
    </>
  )
}

export default withTranslation()(SuccessfulPayment)
