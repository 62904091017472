import {useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import TabBar, {Section} from '../../components/TabBar/TabBar'
import {PATHS} from '../../constants/paths'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {useUser} from '../../contexts/UserContext'
import {useCustomerSlice} from '../../store/slices/customer'

import {panelSections, sections} from './sections/constants'

import styles from './Panel.module.scss'

const Panel = ({}: WithTranslation) => {
  const {user} = useUser()
  const navigate = useNavigate()
  const {
    customer_info: {group},
  } = useCustomerSlice()
  const [activeSection, selectSection] = useState<Section>(panelSections[0].section)

  if ((!!group && group !== 'project-admin') || !user) {
    navigate(PATHS.HOME)
    return null
  }

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <TabBar
          className={styles.tabBar}
          onChangeSection={selectSection}
          sections={sections}
          activeSection={activeSection}
        />
        <div className={styles.line} />
        {
          panelSections.find(
            panelSection => panelSection.section.keyLabel === activeSection.keyLabel,
          )?.sectionComponent
        }
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Panel)
