import axios from 'axios'

import {StatusType} from '../interfaces/IProject'

export interface IDashboard {
  total_funding_pending: number
  total_active_projects: number
  percentage_funding_pending: number
  summary: ISummary[]
}
export interface ISummary {
  project_name: string
  start_date: string
  percentage_tokens_sold: string
  next_expiration_date: string
  total_investor_amount: number
  total_tokens_minted: number
  total_tokens_sold: number
  tokens_pending_to_sell: number
  token_price: number
  total_funding_objective: number
  balance_to_fund: number
  project_status: StatusType
}

export default {
  getDashboardInfo: async (): Promise<IDashboard> => {
    const {data: responseData} = await axios.get('customers/resume/')
    return responseData as IDashboard
  },
}
