import {useEffect, useMemo, useState} from 'react'
import {Controller, FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as Arrow} from '../../../../assets/icons/arrow-prev.svg'
import Button from '../../../../components/Button/Button'
import Dropdown, {IValue} from '../../../../components/Dropdown/Dropdown'
import TextInput from '../../../../components/TextInput/TextInput'
import {FLAG, ICountryValue} from '../../../../constants/countryFlags'
import * as contactForm from '../../../../constants/personalInformationForm'
import {IContactForm} from '../../../../constants/personalInformationForm'
import {REG_EX} from '../../../../constants/regExpression'
import {customerApi} from '../../../../services'
import {COUNTRY} from '../../../../services/interfaces/IAddress'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {usePersonFormsSlice} from '../../../../store/slices/personForms'

import styles from '../InvestorInfo.module.scss'

const ContactForm = ({t}: WithTranslation) => {
  const {lastForm, nextForm} = usePersonFormsSlice()
  const {
    customer_info: {id, email, phone_number, address},
    setCustomerData,
  } = useCustomerSlice()
  const methods = useForm<IContactForm>({
    defaultValues: {
      mail: email || '',
      mobile: phone_number || '',
      street: address?.street || '',
      number: address?.number || '',
      floor: address?.floor || '',
      department: address?.department || '',
      cp: address?.cp || '',
      city: address?.city || '',
      province: address?.province || '',
      country: address?.country,
    },
  })
  const [submitting, setSubmitting] = useState<boolean>(false)

  const countries: ICountryValue[] = useMemo<ICountryValue[]>(
    () =>
      Object.values(COUNTRY).map(value => ({
        key: value,
        label: t(`user.countries.${value.toLocaleLowerCase()}`),
        icon: FLAG[value],
      })),
    [t],
  )

  const onSubmit = async (data: IContactForm) => {
    try {
      setSubmitting(true)
      const responseData = await customerApi.modifyCustomerInfo(id || 0, {
        email: data.mail,
        phone_number: data.mobile.toString(),
        address: {
          street: data.street,
          number: data.number.toString(),
          floor: data.floor || '',
          department: data.department || '',
          cp: data.cp,
          city: data.city,
          province: data.province,
          country: data.country,
        },
      })
      setCustomerData({customer_info: responseData})
      nextForm()
    } catch (error: any) {
      console.log('Error on put data', error)
    } finally {
      setSubmitting(false)
    }
  }
  const onSubmitError = (
    errorData: SubmitErrorHandler<IContactForm> | FieldErrors<IContactForm>,
  ) => {
    console.log('PIForm-ErrorData', errorData)
  }

  useEffect(() => {
    methods.reset({
      mail: email || '',
      mobile: phone_number || '',
      street: address?.street || '',
      number: address?.number || '',
      floor: address?.floor || '',
      department: address?.department || '',
      cp: address?.cp || '',
      city: address?.city || '',
      province: address?.province || '',
      country: address?.country,
    })
  }, [email, phone_number, methods, id])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {<Arrow className={styles.prev} onClick={() => lastForm()} />}
        <span className={styles.title}>{t('personalInformation.title')}</span>
        <span className={styles.clarification}>{t('personalInformation.clarification')}</span>
      </div>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
          <TextInput
            label={`${t(`user.${contactForm.MAIL}`)}`}
            name={contactForm.MAIL}
            pattern={REG_EX.EMAIL}
            withUseForm
            underlined
            required
          />
          <TextInput
            label={`${t(`user.${contactForm.MOBILE}`)}`}
            name={contactForm.MOBILE}
            placeholder={`${t(`user.${contactForm.MOBILE}Placeholder`)}`}
            withUseForm
            underlined
            required
          />
          <div className={classNames(styles.row, styles.special)}>
            <TextInput
              className={styles.street}
              label={`${t(`user.${contactForm.STREET}`)}`}
              name={contactForm.STREET}
              withUseForm
              underlined
              required
            />
            <TextInput
              className={styles.number}
              label={`${t(`user.${contactForm.NUMBER}`)}`}
              name={contactForm.NUMBER}
              withUseForm
              underlined
              required
            />
            <TextInput
              className={styles.floor}
              label={`${t(`user.${contactForm.FLOOR}`)}`}
              name={contactForm.FLOOR}
              withUseForm
              underlined
            />
            <TextInput
              className={styles.department}
              label={`${t(`user.${contactForm.DEPARTMENT}`)}`}
              name={contactForm.DEPARTMENT}
              withUseForm
              underlined
            />
          </div>
          <div className={styles.row}>
            <TextInput
              className={styles.city}
              label={`${t(`user.${contactForm.CITY}`)}`}
              name={contactForm.CITY}
              withUseForm
              underlined
              required
            />
            <TextInput
              label={`${t(`user.${contactForm.POSTAL_CODE}`)}`}
              name={contactForm.POSTAL_CODE}
              withUseForm
              underlined
              required
            />
          </div>
          <TextInput
            label={`${t(`user.${contactForm.PROVINCE}`)}`}
            name={contactForm.PROVINCE}
            withUseForm
            underlined
            required
          />
          <Controller
            control={methods.control}
            name={contactForm.COUNTRY}
            rules={{
              required: true,
            }}
            render={({field: {value, onChange}}) => (
              <Dropdown
                label={`${t(`user.${contactForm.COUNTRY}`)}`}
                name={contactForm.COUNTRY}
                className={styles.dropdown}
                customClasses={{
                  head: styles.head,
                  arrow: styles.arrow,
                }}
                value={countries.find(country => value === country.key) as IValue}
                setValue={onChange}
                options={countries}
                underlined
              />
            )}
          />

          <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            type="submit"
            // disabled={!methods.formState.isValid}
            loading={submitting}>
            {t('continue')}
          </Button>
        </form>
      </FormProvider>
    </div>
  )
}

export default withTranslation()(ContactForm)
