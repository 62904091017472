import {IPaymentFormData} from '@mercadopago/sdk-react/bricks/payment/type'

import {PaymentMeanCurrencyType} from '../../constants/currencies'
import axios from '../../core/axios'
import {IdentityVerificationStatus} from '../interfaces/ICustomerInfo'
import {IReserve} from '../interfaces/IReserve'

export interface IAccountDetail {
  bank: {
    name: string
    country: string
  }
  account_holder: string
  account_routing_number?: string
  ach_code?: string
  cbu_code?: string
  account_type: string
  swift_code?: string
  bank_address: string
  other_details: string
  alias?: string
}
export interface IBankDetail {
  currency: PaymentMeanCurrencyType
  account_number: string
  account_detail: IAccountDetail
}
export interface IOwner {
  id: number
  username: string
  first_name: string
  last_name: string
  email: string
  identity_verification_status: IdentityVerificationStatus
  identity_verification_completed: boolean
}

export interface IReservesData {
  count: number
  next: number
  previous: number
  results: IReserve[]
}
export enum STATUS {
  pending = 'pending',
  approved = 'approved',
  authorized = 'authorized',
  in_process = 'in_process',
  in_mediation = 'in_mediation',
  rejected = 'rejected',
  cancelled = 'cancelled',
  refunded = 'refunded',
  charged_back = 'charged_back',
}
export enum STATUS_DETAIL {
  accredited = 'accredited',
  pending_contingency = 'pending_contingency',
  pending_review_manual = 'pending_review_manual',
  cc_rejected_bad_filled_date = 'cc_rejected_bad_filled_date',
  cc_rejected_bad_filled_other = 'cc_rejected_bad_filled_other',
  cc_rejected_bad_filled_security_code = 'cc_rejected_bad_filled_security_code',
  cc_rejected_blacklist = 'cc_rejected_blacklist',
  cc_rejected_call_for_authorize = 'cc_rejected_call_for_authorize',
  cc_rejected_card_disabled = 'cc_rejected_card_disabled',
  cc_rejected_duplicated_payment = 'cc_rejected_duplicated_payment',
  cc_rejected_high_risk = 'cc_rejected_high_risk',
  cc_rejected_insufficient_amount = 'cc_rejected_insufficient_amount',
  cc_rejected_invalid_installments = 'cc_rejected_invalid_installments',
  cc_rejected_max_attempts = 'cc_rejected_max_attempts',
  cc_rejected_other_reason = 'cc_rejected_other_reason',
}
export type StatusDetail = keyof typeof STATUS_DETAIL
export type Status = keyof typeof STATUS
export interface IMercadoPagoPaymentResult {
  status: Status
  status_detail: StatusDetail
}
export default {
  buyWithWeb3: (quantity: number, token_id: number) =>
    axios.post('sales/w3buy/', {
      quantity,
      token_id,
    }),
  createPaymentLink: (quantity: number, token_id: number) =>
    axios.post('sales/create_payment_link/', {
      quantity,
      token_id,
    }),
  requestApprove: (quantity: number, token_id: number) =>
    axios.post('sales/approve/', {
      quantity,
      token_id,
    }),
  onBuySuccess: (
    block_number: number,
    transaction_hash: string,
    token_id: number,
    quantity?: number,
  ) =>
    axios.post('sales/web3/success/', {
      block_number,
      token_id,
      transaction_hash,
      quantity,
    }),
  stripeHook: () => axios.post('sales/stripeHook/'),
  reserve: (quantity: number, token_id: number, currency: PaymentMeanCurrencyType, file: string) =>
    axios.post('sales/reserve/', {
      quantity,
      token_id,
      currency,
      file,
    }),
  lemon: async (token_amount: number, token_id: number): Promise<string> => {
    const {
      data: {lemon_payment_url},
    } = await axios.post('sales/lemon/', {
      token_amount,
      token_id,
    })
    return lemon_payment_url
  },

  getBankDetails: async (tokenId: number): Promise<IBankDetail[]> => {
    const {data} = await axios.get(`sales/bank_details/${tokenId}/`)
    return data as IBankDetail[]
  },
  allowance: async (quantity: number, token_id: number): Promise<boolean> => {
    const {
      data: {allowed},
    } = await axios.post('sales/allowance/', {quantity, token_id})
    return allowed
  },
  uploadFile: async (file: FormData): Promise<string> => {
    const {
      data: {file_route},
    } = await axios.post('sales/upload/', file)
    return file_route
  },
  getReserves: async (): Promise<IReservesData> => {
    const {data} = await axios.get('sales/reserves/')
    return data
  },
  getPendingReservesCount: async (): Promise<number> => {
    const {
      data: {pending_reservations},
    } = await axios.get('sales/reserves/check/')
    return pending_reservations
  },
  confirmReserves: async (reservation_ids: number[]): Promise<IReservesData> => {
    const {data} = await axios.post('sales/reserves/confirm/', {reservation_ids})
    return data
  },
  createMercadoPagoLink: async (
    quantity: number,
    token_id: number,
    paymentFormData: IPaymentFormData,
  ): Promise<IMercadoPagoPaymentResult> => {
    const {
      data: {
        url_pago: {status, status_detail},
      },
    } = await axios.post('sales/create_mercadopago_link/', {
      ...paymentFormData.formData,
      quantity,
      token_id,
      description: 'Payment',
    })
    return {status, status_detail}
  },
}
