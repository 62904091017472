import {Trans, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as WelcomeBannerLogo} from '../../assets/brandLogos/brand-welcome-banner-logo.svg'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './WelcomeBanner.module.scss'

interface IWelcomeBanner {
  design?: 'first' | 'second'
}
const WelcomeBanner = ({design = 'first'}: IWelcomeBanner) => (
  <BannerSection className={styles.section}>
    <BannerContainer className={classNames(styles.container, styles[design])} layout="column">
      {design === 'first' && (
        <div className={styles.title}>
          <Trans i18nKey="welcomeBanner.title">
            Welcome to <br />
            <span>Bridge Positive</span>
          </Trans>
        </div>
      )}
      {design === 'second' && <WelcomeBannerLogo />}
      <div className={classNames(styles.subtitle, styles[design])}>
        <Trans i18nKey="welcomeBanner.subtitle">
          Invest in tokenized Real Estate in three simple steps
        </Trans>
      </div>
    </BannerContainer>
  </BannerSection>
)

export default withTranslation()(WelcomeBanner)
