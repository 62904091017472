import classNames from 'classnames'

import {ReactComponent as Error} from '../../assets/icons/error.svg'
import {ReactComponent as Info} from '../../assets/icons/info.svg'
import {ReactComponent as Warning} from '../../assets/icons/warning.svg'

import styles from './InfoPanel.module.scss'

interface IInfoPanel {
  className?: string
  label?: string | null
  description?: string | null
  type: 'error' | 'warning' | 'info'
}

const ICON = {
  error: <Error />,
  warning: <Warning />,
  info: <Info />,
}

const InfoPanel = ({className, label, description, type}: IInfoPanel) => (
  <div className={classNames(styles.container, className, styles[type])}>
    {ICON[type]}
    <div className={styles.text}>
      {!!label && <span className={styles.label}>{label}</span>}
      {!!description && <span className={styles.description}>{description}</span>}
    </div>
  </div>
)

export default InfoPanel
