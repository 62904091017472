import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import {ReactComponent as Download} from '../../../../assets/icons/download.svg'
import {ReactComponent as Empty} from '../../../../assets/icons/empty-state.svg'
import Card from '../../../../components/Card/Card'
import Checkbox from '../../../../components/Checkbox/Checkbox'
import {CURRENCY} from '../../../../constants/currencies'
import {saleApi} from '../../../../services'
import {PAYMENT_DETAIL_STATUS} from '../../../../services/api/payment'
import {IReserve} from '../../../../services/interfaces/IReserve'
import {formatDate} from '../../../../utils/formatDate'
import {formatNumber} from '../../../../utils/formatNumber'
import {PANEL_SECTION} from '../constants'

import ReserveBox from './components/ReserveBox/ReserveBox'

import styles from './Reserves.module.scss'

enum HEADER_TABLE {
  client = 'client',
  status = 'status',
  description = 'description',
  token = 'token',
  reserveDate = 'reserveDate',
  amount = 'amount',
  receipt = 'receipt',
}
const headers: (keyof typeof HEADER_TABLE)[] = Object.values(HEADER_TABLE)

const Reserves = ({t}: WithTranslation) => {
  const [reserves, setReserves] = useState<IReserve[]>()
  const [isFetching, setFetching] = useState<boolean>(false)
  const [isAllSelected, selectAll] = useState<boolean>(false)
  const [selectedReserves, updateSelectedReserves] = useState<IReserve[]>([])

  const getReserves = useCallback(async () => {
    try {
      setFetching(true)
      const response = await saleApi.getReserves()
      setReserves(response.results)
    } catch (error) {
      console.log(error)
    } finally {
      setFetching(false)
    }
  }, [])

  const selectReserve = useCallback(
    (reserve: IReserve, checked: boolean) => {
      if (checked && !selectedReserves.some(selectedReserve => selectedReserve.id === reserve.id))
        updateSelectedReserves([...selectedReserves, reserve])
      else if (!checked)
        updateSelectedReserves(
          selectedReserves.filter(selectedReserve => selectedReserve.id !== reserve.id),
        )
    },
    [selectedReserves, updateSelectedReserves],
  )

  const selectAllReserves = useCallback(
    (checked: boolean) => {
      if (!reserves) return
      if (checked) {
        const filteredReserves = reserves.filter(
          reserve => reserve.payment_status.toLocaleLowerCase() !== PAYMENT_DETAIL_STATUS.confirmed,
        )
        updateSelectedReserves(filteredReserves)
      } else updateSelectedReserves([])
    },
    [isAllSelected, reserves, updateSelectedReserves, selectedReserves],
  )

  const refresh = () => {
    getReserves()
    updateSelectedReserves([])
  }

  const onReserve = useCallback(() => {
    try {
      return saleApi.confirmReserves(selectedReserves.map(selectedReserve => selectedReserve.id))
    } catch (error) {
      console.log('Error on reserving', error)
    }
  }, [selectedReserves])

  useEffect(() => {
    getReserves()
  }, [])

  useEffect(() => {
    if (!reserves) return
    if (
      selectedReserves.length ===
        reserves.filter(
          reserve => reserve.payment_status.toLocaleLowerCase() !== PAYMENT_DETAIL_STATUS.confirmed,
        ).length &&
      !isAllSelected
    )
      selectAll(true)
    else if (selectedReserves.length !== reserves.length && isAllSelected) selectAll(false)
  }, [selectedReserves, reserves])

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <div className={styles.textWrapper}>
          <span className={styles.title}>
            {' '}
            {t(`panel.section.${PANEL_SECTION.reserves}.title`)}
          </span>
        </div>
        <div className={styles.buttons}></div>
      </div>
      <div className={styles.body}>
        <Card className={classNames(styles.card, styles.tableCard)} withShadow>
          <table>
            <tbody>
              <tr>
                <th className={styles.check}>
                  <Checkbox
                    checked={isAllSelected}
                    onChange={event => selectAllReserves(event?.target?.checked)}
                  />
                </th>
                {headers.map(header => (
                  <th key={header} className={styles[header]}>
                    <span className={styles[header]}>
                      {t(`panel.section.${PANEL_SECTION.reserves}.header.${header}`)}
                    </span>
                  </th>
                ))}
              </tr>
              {!isFetching &&
                !!reserves?.length &&
                reserves.map((reserve: IReserve, index: number) => (
                  <tr key={index}>
                    <td className={styles.check}>
                      <Checkbox
                        checked={selectedReserves.some(
                          selectedReserve => selectedReserve.id === reserve.id,
                        )}
                        onChange={event => selectReserve(reserve, event?.target?.checked)}
                        disabled={
                          reserve.payment_status.toLocaleLowerCase() ===
                          PAYMENT_DETAIL_STATUS.confirmed
                        }
                      />
                    </td>
                    <td className={styles.name}>
                      <span>{`${reserve.owner.first_name} ${reserve.owner.last_name}`}</span>
                    </td>
                    <td className={styles.status}>
                      <span className={styles[reserve.payment_status.toLocaleLowerCase()]}>
                        {t(
                          `panel.section.${
                            PANEL_SECTION.reserves
                          }.status.${reserve.payment_status.toLocaleLowerCase()}`,
                        )}
                      </span>
                    </td>
                    <td>
                      <span>
                        {t(
                          `panel.section.${
                            PANEL_SECTION.reserves
                          }.description.${reserve.payment_method.toLowerCase()}`,
                        )}
                      </span>
                    </td>
                    <td>
                      <span>{reserve.token?.name || '-'}</span>
                    </td>
                    <td>
                      <span>{formatDate(reserve.created_at)}</span>
                    </td>
                    <td>
                      <span>
                        {formatNumber(reserve.total_paid)} {CURRENCY.USDT}
                      </span>
                    </td>
                    <td>
                      <a
                        className={styles.downloadLinkButton}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={reserve.receipt + '?download=true'}
                        data-ipfs
                        download="comprobante de reserva">
                        <Download className={styles.download} />
                      </a>
                    </td>
                  </tr>
                ))}
              {isFetching &&
                Array.from(Array(5).keys()).map(skeletonRow => (
                  <tr key={skeletonRow}>
                    {Array.from(Array(headers.length).keys()).map(skeleton => (
                      <td key={skeleton}>{skeleton !== 0 && <Skeleton width="50%" />}</td>
                    ))}
                  </tr>
                ))}
              {!isFetching && !reserves?.length && (
                // <tr className={styles.emptyText}>
                //   <td>{t('profile.noRecentTransaction')}</td>
                // </tr>
                <tr className={styles.emptyState}>
                  <td colSpan={headers.length + 1}>
                    <section>
                      <Empty />
                      <span>{t(`panel.section.${PANEL_SECTION.reserves}.noReserves`)}</span>
                    </section>
                  </td>
                </tr>
              )}
            </tbody>
            {/* <tfoot>
              {!!payments?.next && (
                <tr className={styles.seeMore}>
                  <td onClick={() => fetchNextPage(payments.next)}>
                    <span>{t('seeMore')}</span> <ArrowDown />
                  </td>
                </tr>
              )}
            </tfoot> */}
          </table>
        </Card>
        <ReserveBox
          className={styles.card}
          selectedReserves={selectedReserves.length}
          total={selectedReserves.reduce(
            (accumulator, currentValue) => accumulator + currentValue.total_paid,
            0,
          )}
          onReserve={onReserve}
          onFinishReserveFlow={refresh}
        />
      </div>
    </div>
  )
}

export default withTranslation()(Reserves)
