import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as AvailableTokens} from '../../../../assets/icons/available-tokens.svg'
import {ReactComponent as BillCheck} from '../../../../assets/icons/bill-check.svg'
import {ReactComponent as Calendar} from '../../../../assets/icons/calendar-small.svg'
import {ReactComponent as CalendarTime} from '../../../../assets/icons/calendar-time.svg'
import {ReactComponent as ChartLine} from '../../../../assets/icons/chart-line.svg'
import {ReactComponent as Coins} from '../../../../assets/icons/coins.svg'
import {ReactComponent as ColumnChart} from '../../../../assets/icons/column-chart.svg'
import {ReactComponent as EstimatedReturnIcon} from '../../../../assets/icons/estimated-return.svg'
import {ReactComponent as PieChart} from '../../../../assets/icons/pie-chart-white.svg'
import {ReactComponent as Receive} from '../../../../assets/icons/receive.svg'
import {ReactComponent as Redirect} from '../../../../assets/icons/redirect.svg'
import {ReactComponent as Star} from '../../../../assets/icons/star.svg'
import {ReactComponent as TokenPriceIcon} from '../../../../assets/icons/token-price.svg'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Card/Card'
import ItemProperty from '../../../../components/ItemCard/components/ItemProperty/ItemProperty'
import {CURRENCY_SYMBOL} from '../../../../constants/currencies'
import {BUSINESS_MODEL_TYPE} from '../../../../services/interfaces/IProject'
import {useProjectSlice} from '../../../../store/slices/project'
import {formatDate} from '../../../../utils/formatDate'
import {formatNumber} from '../../../../utils/formatNumber'
import TokenCounter from '../TokenCounter/TokenCounter'

import styles from './Tokenomic.module.scss'

interface ITokenomicProps extends WithTranslation {
  investAction: () => void
  straightCorners?: boolean
}

const Tokenomic = ({t, investAction, straightCorners}: ITokenomicProps) => {
  const {
    business_model,
    tokenomic: {
      total_objective,
      minimum_investment,
      return_over_investment,
      estimated_return,
      dividend_period,
      rental_return_over_investment,
      capital_gain_after_sale,
      rent_start_date,
      project_duration,
      variable_capital_gain_after_sale,
      token,
    },
  } = useProjectSlice()

  return (
    <Card className={styles.card} withShadow straightCorners={straightCorners}>
      <div
        className={classNames(styles.investmentBox, {[styles.straightCorners]: straightCorners})}>
        {!!total_objective && (
          <ItemProperty
            icon="$"
            label={`${t('item.totalFund')}`}
            property={
              <>
                <span>{formatNumber(+total_objective || 0)}</span>{' '}
                {CURRENCY_SYMBOL[token?.currency]}
              </>
            }
            oneLine
          />
        )}
        {!!project_duration && (
          <ItemProperty
            icon={<Calendar className={styles['path-fill']} />}
            label={`${t('item.projectDuration')}`}
            property={
              <span>
                {project_duration}{' '}
                {(+project_duration > 1 ? t('months') : t('month'))?.toLowerCase()}
              </span>
            }
            oneLine
          />
        )}
        {!!token?.price && (
          <ItemProperty
            icon={<TokenPriceIcon className={styles['path-stroke']} />}
            label={`${t('item.tokenPrice')}`}
            property={
              <>
                <span>{formatNumber(+token?.price || 0)}</span> {CURRENCY_SYMBOL[token?.currency]}
              </>
            }
            oneLine
          />
        )}
        {!!token?.total_supply && (
          <ItemProperty
            icon={<BillCheck className={styles['path-stroke']} />}
            label={`${t('item.tokensCreated')}`}
            property={<span>{formatNumber(+token.total_supply)}</span>}
            oneLine
          />
        )}
        {!!token?.available_stock && (
          <ItemProperty
            icon={<AvailableTokens className={styles['path-stroke']} />}
            label={`${t('item.availableTokens')}`}
            property={<span>{formatNumber(+token.available_stock || 0)}</span>}
            oneLine
          />
        )}
        {!!variable_capital_gain_after_sale && (
          <ItemProperty
            icon={<ChartLine className={styles['path-fill']} />}
            label={`${t('item.variableCapitalGainAfterSale')}`}
            property={<span>{t('yes')}</span>}
            oneLine
          />
        )}
        {!!minimum_investment && (
          <ItemProperty
            icon={<ColumnChart className={styles['path-fill']} />}
            label={`${t('item.minimumInvestment')}`}
            property={
              <span className={styles.percentage}>{`${+minimum_investment || 0} token${
                (+minimum_investment > 1 && 's') || ''
              }`}</span>
            }
            oneLine
          />
        )}
        {!!return_over_investment && (
          <ItemProperty
            icon={<EstimatedReturnIcon className={styles['path-stroke-fill']} />}
            label={`${t('item.estimatedAnnualReturn')}`}
            property={
              <span className={styles.percentage}>
                {formatNumber(+return_over_investment || 0)}%
              </span>
            }
            oneLine
          />
        )}
        {!!dividend_period && (
          <ItemProperty
            icon={<CalendarTime className={styles['path-fill']} />}
            label={`${t('item.estimatedPeriod')}`}
            property={
              <span className={styles.label}>
                {dividend_period} {(+dividend_period > 1 ? t('months') : t('month'))?.toLowerCase()}
              </span>
            }
            oneLine
          />
        )}
        {business_model !== BUSINESS_MODEL_TYPE.RENT_CAPITAL_GAIN.toLocaleUpperCase() &&
          !!estimated_return && (
            <ItemProperty
              icon={<PieChart className={styles['path-fill']} />}
              label={`${t('item.projectRateOfReturn')}`}
              property={
                <span className={styles.percentage}>{formatNumber(+estimated_return || 0)}%</span>
              }
              oneLine
            />
          )}
        {business_model === BUSINESS_MODEL_TYPE.RENT_CAPITAL_GAIN.toLocaleUpperCase() && (
          <>
            {!!rental_return_over_investment && (
              <ItemProperty
                icon={<Coins className={styles['path-stroke']} />}
                label={`${t('item.rentalReturnOverInvestment')}`}
                property={
                  <span className={styles.percentage}>
                    {formatNumber(+rental_return_over_investment || 0)}%
                  </span>
                }
                oneLine
              />
            )}
            {!!capital_gain_after_sale && (
              <ItemProperty
                icon={<Receive className={styles['path-stroke']} />}
                label={`${t('item.capitalGainAfterSale')}`}
                property={
                  <span className={styles.percentage}>
                    {formatNumber(+capital_gain_after_sale || 0)}%
                  </span>
                }
                oneLine
              />
            )}
            {!!rent_start_date && (
              <ItemProperty
                icon={<CalendarTime className={styles['path-stroke']} />}
                label={`${t('item.rentStartDate')}`}
                property={<span className={styles.label}>{formatDate(rent_start_date)}</span>}
                oneLine
              />
            )}
          </>
        )}
        {!!token?.name && !!token?.nft_contract_address && (
          <ItemProperty
            icon={<Star className={styles['path-fill']} />}
            label={`${t('item.tokenDetail')}`}
            property={
              <span className={styles.redirect}>
                {token?.name}
                <a
                  className={styles.buttonRedirect}
                  href={`https://mumbai.polygonscan.com/token/${token?.nft_contract_address}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Redirect />
                </a>
              </span>
            }
            oneLine
          />
        )}
        <div className={styles.line} />
        <div className={styles.footer}>
          <TokenCounter />

          <Button className={styles.button} filledColor="primary" onClick={() => investAction()}>
            {t('invest')}
          </Button>
        </div>
      </div>
    </Card>
  )
}

export default withTranslation()(Tokenomic)
