import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import {ReactComponent as ArrowPrev} from '../../../../../../assets/icons/arrow-prev.svg'
import {ReactComponent as Empty} from '../../../../../../assets/icons/empty-state.svg'
import Card from '../../../../../../components/Card/Card'
import Checkbox from '../../../../../../components/Checkbox/Checkbox'
import {paymentApi} from '../../../../../../services'
import {
  IBlockchainInfo,
  IPayment,
  IPaymentDetail,
  PAYMENT_DETAIL_STATUS,
} from '../../../../../../services/api/payment'
import {formatDate} from '../../../../../../utils/formatDate'
import {formatNumber} from '../../../../../../utils/formatNumber'
import {PANEL_SECTION} from '../../../constants'
import PaymentBox from '../../components/PaymentBox/PaymentBox'

import styles from './PaymentDetails.module.scss'

enum HEADER_TABLE {
  client = 'client',
  status = 'status',
  description = 'description',
  dueDate = 'dueDate',
  amount = 'amount',
}
const headers: (keyof typeof HEADER_TABLE)[] = Object.values(HEADER_TABLE)

interface IPaymentDetails extends WithTranslation {
  payment: IPayment
  onPrev: () => void
}

const PaymentDetails = ({t, payment, onPrev}: IPaymentDetails) => {
  const [paymentDetails, setPaymentDetails] = useState<IPaymentDetail[]>([])
  const [isFetching, setFetching] = useState<boolean>(false)
  const [isAllSelected, selectAll] = useState<boolean>(false)
  const [subTotal, setSubtotal] = useState<number>(0)
  const [selectedPayments, updateSelectedPayments] = useState<IPaymentDetail[]>([])

  const getPaymentDetails = useCallback(async () => {
    try {
      setFetching(true)
      const response = await paymentApi.getPaymentsDetail(payment.token_id, payment.due_date)
      setPaymentDetails(response)
    } catch (error) {
      console.log(error)
    } finally {
      setFetching(false)
    }
  }, [payment, isFetching, paymentDetails])

  const selectPaymentDetail = useCallback(
    (paymentDetail: IPaymentDetail, checked: boolean) => {
      if (
        checked &&
        !selectedPayments.some(selectedPayment => selectedPayment.id === paymentDetail.id)
      ) {
        updateSelectedPayments([...selectedPayments, paymentDetail])
        setSubtotal(subTotal + +paymentDetail.projected_payment_amount)
      } else if (!checked) {
        updateSelectedPayments(
          selectedPayments.filter(selectedPayment => selectedPayment.id !== paymentDetail.id),
        )
        setSubtotal(subTotal - +paymentDetail.projected_payment_amount)
      }
    },
    [selectedPayments, subTotal, setSubtotal, updateSelectedPayments],
  )

  const selectAllPaymentDetails = useCallback(
    (checked: boolean) => {
      if (!paymentDetails) return
      if (checked) {
        const filteredPayments = paymentDetails.filter(
          payment => payment.payment_status.toLocaleLowerCase() !== PAYMENT_DETAIL_STATUS.confirmed,
        )
        updateSelectedPayments(filteredPayments)
        setSubtotal(
          filteredPayments.reduce(
            (accumulator, currentValue) => accumulator + +currentValue.projected_payment_amount,
            0,
          ),
        )
      } else {
        updateSelectedPayments([])
        setSubtotal(0)
      }
    },
    [
      isAllSelected,
      paymentDetails,
      updateSelectedPayments,
      setSubtotal,
      subTotal,
      selectedPayments,
    ],
  )

  const refresh = () => {
    getPaymentDetails()
    updateSelectedPayments([])
    setSubtotal(0)
  }

  const onPay = useCallback(
    () => paymentApi.payPaymentDetails(selectedPayments),
    [selectedPayments],
  )

  const onPaymentSuccess = useCallback(
    async (blockchain_info: IBlockchainInfo[]) =>
      await paymentApi.onPayDetailsSuccess(blockchain_info[0], selectedPayments),
    [selectedPayments],
  )

  useEffect(() => {
    getPaymentDetails()
  }, [])

  useEffect(() => {
    if (!paymentDetails?.length) return
    if (
      selectedPayments.length ===
        paymentDetails.filter(
          payment => payment.payment_status.toLocaleLowerCase() !== PAYMENT_DETAIL_STATUS.confirmed,
        ).length &&
      !isAllSelected
    )
      selectAll(true)
    else if (selectedPayments.length !== paymentDetails.length && isAllSelected) selectAll(false)
  }, [selectedPayments, paymentDetails])

  return (
    <>
      <div className={styles.header}>
        <div className={styles.textWrapper}>
          <span className={styles.title}>
            <div className={styles.return} onClick={onPrev}>
              <ArrowPrev />
              <span>{t('return')}</span>
            </div>
            {`${t('panel.section.payments.detail')} ${payment.project_name.toUpperCase()}`}
          </span>
        </div>
        <div className={styles.buttons}></div>
      </div>
      <div className={styles.body}>
        <Card className={classNames(styles.card, styles.tableCard)} withShadow>
          <table>
            <tbody>
              <tr>
                <th className={styles.check}>
                  <Checkbox
                    checked={isAllSelected}
                    onChange={event => selectAllPaymentDetails(event?.target?.checked)}
                  />
                </th>
                {headers.map(header => (
                  <th key={header} className={styles[header]}>
                    <span className={styles[header]}>
                      {t(`panel.section.${PANEL_SECTION.payments}.header.${header}`)}
                    </span>
                  </th>
                ))}
                <th></th>
              </tr>
              {!isFetching &&
                !!paymentDetails?.length &&
                paymentDetails.map((paymentDetail: IPaymentDetail, index: number) => (
                  <tr key={index}>
                    <td className={styles.check}>
                      <Checkbox
                        checked={selectedPayments.some(
                          selectedPayment => selectedPayment.id === paymentDetail.id,
                        )}
                        disabled={
                          paymentDetail.payment_status.toLocaleLowerCase() ===
                          PAYMENT_DETAIL_STATUS.confirmed
                        }
                        onChange={event =>
                          selectPaymentDetail(paymentDetail, event?.target?.checked)
                        }
                      />
                    </td>
                    <td className={styles.client}>
                      <span>{paymentDetail.customer}</span>
                    </td>
                    <td className={styles.status}>
                      <span className={styles[paymentDetail.payment_status.toLocaleLowerCase()]}>
                        {t(
                          `panel.section.${
                            PANEL_SECTION.payments
                          }.status.${paymentDetail.payment_status.toLocaleLowerCase()}`,
                        )}
                      </span>
                    </td>
                    <td className={styles.description}>
                      <span>
                        {t(
                          `panel.section.${PANEL_SECTION.payments}.description.${paymentDetail.description}`,
                        )}
                      </span>
                    </td>
                    <td className={styles.date}>
                      <span>{formatDate(paymentDetail.payment_date)}</span>
                    </td>
                    <td className={styles.amount}>
                      <span>$ {formatNumber(+paymentDetail.projected_payment_amount)}</span>
                    </td>
                    <td className={styles.actions}></td>
                  </tr>
                ))}
              {isFetching &&
                Array.from(Array(5).keys()).map(skeletonRow => (
                  <tr key={skeletonRow}>
                    {Array.from(Array(headers.length + 1).keys()).map(skeleton => (
                      <td key={skeleton}>{skeleton !== 0 && <Skeleton width="50%" />}</td>
                    ))}
                  </tr>
                ))}
              {!isFetching && !paymentDetails?.length && (
                // <tr className={styles.emptyText}>
                //   <td>{t('profile.noRecentTransaction')}</td>
                // </tr>
                <tr className={styles.emptyState}>
                  <td colSpan={7}>
                    <section>
                      <Empty />
                      <span>{t(`panel.section.${PANEL_SECTION.payments}.noPayments`)}</span>
                    </section>
                  </td>
                </tr>
              )}
            </tbody>
            {/* <tfoot>
        {!!payments?.next && (
          <tr className={styles.seeMore}>
            <td onClick={() => fetchNextPage(payments.next)}>
              <span>{t('seeMore')}</span> <ArrowDown />
            </td>
          </tr>
        )}
      </tfoot> */}
          </table>
        </Card>
        <PaymentBox
          className={styles.card}
          paymentsSelected={selectedPayments.length}
          subTotal={subTotal}
          onPay={onPay}
          onPaymentSuccess={onPaymentSuccess}
          onFinishPaymentFlow={refresh}
        />
      </div>
    </>
  )
}

export default withTranslation()(PaymentDetails)
