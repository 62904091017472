import {Fragment, useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'
import copyToClipboard from 'copy-to-clipboard'

import {ReactComponent as Copy} from '../../../../assets/icons/copy.svg'
import {ReactComponent as Deposit} from '../../../../assets/icons/deposit.svg'
import {ReactComponent as Withdrawal} from '../../../../assets/icons/withdrawal.svg'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Card/Card'
import OnBoardingModal from '../../../../components/OnBoardingModal/OnBoardingModal'
import {CURRENCY, currentCurrency} from '../../../../constants/currencies'
import {PATHS} from '../../../../constants/paths'
import {useUser} from '../../../../contexts/UserContext'
import {useWallet} from '../../../../contexts/WalletContext'
import {useWeb3} from '../../../../contexts/Web3Context'
import useBreakpoint from '../../../../hooks/useBreakpoint'
import {magic} from '../../../../libs/magic'
import {onBoardingApi} from '../../../../services'
import {
  IOnBoardingError,
  IOnboardingResponse,
  ONBOARDING_SECTION,
} from '../../../../services/api/onBoarding'
import {ONBOARDING_TYPE, useOnBoarding} from '../../../../store/slices/onBoarding'

import styles from './MyWallet.module.scss'

interface IMyWallet extends WithTranslation {
  className?: string
}

const MyWallet = ({t}: IMyWallet) => {
  const navigate = useNavigate()
  const {user, balance, getBalance, fetchingBalance} = useUser()
  const {web3} = useWeb3()
  const {disconnectWallet} = useWallet()
  const {activeStep, totalSteps, lastStep, nextStep, onBoardingCompleted, setOnBoardingCompleted} =
    useOnBoarding()
  const [actionInProgress, setActionInProgress] = useState<string>()
  const [isMagic, setMagic] = useState<boolean>()
  const breakpoint = useBreakpoint()

  const openWallet = async (action: string) => {
    try {
      setActionInProgress(action)
      if (isMagic) {
        await (magic.wallet.showUI() as any).on('disconnect', () => {
          disconnectWallet()
        })
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setActionInProgress('')
    }
  }

  const getWalletType = useCallback(async () => {
    try {
      const {walletType} = await magic.wallet.getInfo()
      setMagic(walletType === 'magic')
    } catch (error: any) {
      if (error?.code === -32603) return
      console.error(error)
    }
  }, [])

  const isOnBoardingCompleted = useCallback(async () => {
    try {
      const response: IOnboardingResponse | IOnBoardingError =
        await onBoardingApi.checkOnBoardingCompleted(ONBOARDING_SECTION.portfolio)
      setOnBoardingCompleted(!!(response as IOnboardingResponse)?.completed)
    } catch (error: any) {
      console.error(error)
    }
  }, [setOnBoardingCompleted, onBoardingCompleted])

  useEffect(() => {
    if (!user || !web3) return
    getBalance()
    getWalletType()
    isOnBoardingCompleted()
  }, [user, web3])

  const WButtons = () => (
    <div className={styles.buttons}>
      <OnBoardingModal
        className={styles.onBoardingDeposit}
        description={
          'Desde aquí podrás realizar Depósitos y Retiros de capital cada vez que necesites'
        }
        visible={activeStep.name === ONBOARDING_TYPE.DEPOSIT && !onBoardingCompleted}
        activeStep={activeStep.number}
        totalSteps={totalSteps}
        lastStep={lastStep}
        nextStep={nextStep}
      />
      <Button
        filledColor="white"
        className={classNames(
          styles.button,
          (activeStep.name === ONBOARDING_TYPE.DEPOSIT && !onBoardingCompleted && 'dim') || '',
        )}
        onClick={() => openWallet('deposit')}
        disabled={!isMagic}
        loading={actionInProgress === 'deposit'}>
        <Deposit />
        <span>{t('profile.deposit')}</span>
      </Button>
      <Button
        filledColor="white"
        className={styles.button}
        onClick={() => navigate(PATHS.FUNDS_WITHDRAWAL)}
        disabled={!isMagic}
        loading={actionInProgress === 'withdrawal'}>
        <Withdrawal />
        <span>{t('profile.withdrawal')}</span>
      </Button>
    </div>
  )

  return (
    <>
      <Card
        className={classNames(
          styles.myWallet,
          (!fetchingBalance &&
            !!user &&
            activeStep.name === ONBOARDING_TYPE.WALLET &&
            !onBoardingCompleted &&
            'dim') ||
            '',
        )}
        withShadow>
        <div className={styles.header}>
          <span>{t('profile.myWallet')}</span>
          <span className={styles.address} onClick={() => !!user && copyToClipboard(user)}>
            {(!!user &&
              `${user?.substring(0, 6)}...${user?.substring(user?.length - 6, user?.length)}`) || (
              <Skeleton width={150} />
            )}
            <Copy />
          </span>
        </div>
        <div className={classNames(styles.row, {[styles.loading]: fetchingBalance})}>
          <div className={styles.assets}>
            <div className={styles.column}>
              <div className={styles.text}>{t('profile.balance')}</div>
              {(!fetchingBalance && (
                <div className={styles.value}>
                  {balance}
                  <span>{currentCurrency}</span>
                  <span>≈</span>
                  <span className={styles.officialCurrency}>
                    {balance} {CURRENCY.USDT}
                  </span>
                </div>
              )) || (
                <div className={classNames(styles.value, {[styles.loading]: fetchingBalance})}>
                  <Skeleton width={100} height={40} />
                  <span>≈</span>
                  <Skeleton width={50} height={20} />
                </div>
              )}
            </div>
          </div>
          {breakpoint !== 's' && breakpoint !== 'xs' ? <WButtons /> : <></>}
        </div>
        <OnBoardingModal
          className={styles.onBoardingWallet}
          description={'Aquí veras un resumen general de tus Inversiones'}
          visible={
            !fetchingBalance &&
            !!user &&
            activeStep.name === ONBOARDING_TYPE.WALLET &&
            !onBoardingCompleted
          }
          activeStep={activeStep.number}
          totalSteps={totalSteps}
          lastStep={lastStep}
          nextStep={nextStep}
        />
      </Card>
      {breakpoint === 's' || breakpoint === 'xs' ? <WButtons /> : <></>}
    </>
  )
}
export default withTranslation()(MyWallet)
