import {HTMLInputTypeAttribute, useRef} from 'react'
import {Controller, useFormContext, ValidationRule} from 'react-hook-form'
import classNames from 'classnames'

import {ReactComponent as Date} from '../../assets/icons/date.svg'

import styles from './TextInput.module.scss'

interface ITextInputProps {
  className?: string
  type?: HTMLInputTypeAttribute
  label?: string
  name?: string
  placeholder?: string
  pattern?: ValidationRule<RegExp>
  filledColor?: 'primary' | 'secondary' | 'light' | 'transparent' | 'white'
  withBorder?: boolean
  underlined?: boolean
  withUseForm?: boolean
  required?: boolean
  disabled?: boolean
}
const TextInput = ({
  className,
  type = 'text',
  label,
  name,
  placeholder,
  pattern,
  withBorder = false,
  underlined = false,
  withUseForm = false,
  required = false,
  filledColor = 'white',
  disabled,
}: ITextInputProps) => {
  const {
    control,
    formState: {errors},
  } = (withUseForm && useFormContext()) || {formState: {}}
  const inputRef = useRef(null)
  return (
    <div className={classNames(styles.container, className, {[styles.underlined]: underlined})}>
      {label && (
        <span
          className={classNames(styles.label, {
            [styles.error]: !!errors && !!errors[name || ''],
          })}>
          {label}
        </span>
      )}
      {withUseForm ? (
        <Controller
          control={control}
          name={name || ''}
          rules={{
            required: required,
            // {value: required, message: t('')},
            pattern: pattern,
          }}
          render={({field: {name, ...rest}}) => (
            <>
              <div
                className={classNames(
                  styles.box,
                  //  className,
                  styles[`background-${filledColor}`],
                  {
                    [styles.withBorder]: withBorder,
                    [styles.underlined]: underlined,
                    [styles.error]: !!errors && !!errors[name],
                  },
                )}>
                <input
                  type={type}
                  placeholder={placeholder}
                  className={classNames(styles.input)}
                  disabled={disabled}
                  {...rest}
                  ref={inputRef}
                />
                {type === 'date' && (
                  <Date onClick={() => (inputRef as any)?.current.showPicker()} />
                )}
              </div>
              {/* <div className={classNames(styles.errorContainer, {[styles.error]: !!errors[name]})}>
                <div role="alert" className={styles.errorLabel}>
                  {errors[name]?.message?.toString()}
                </div>
              </div> */}
            </>
          )}
        />
      ) : (
        <>
          <div
            className={classNames(
              styles.box,
              //  className,
              styles[`background-${filledColor}`],
              {
                [styles.withBorder]: withBorder,
                [styles.underlined]: underlined,
              },
            )}>
            <input
              type={type}
              placeholder={placeholder}
              className={classNames(styles.input)}
              disabled={disabled}
              ref={inputRef}
            />
            {type === 'date' && <Date onClick={() => (inputRef as any)?.current.showPicker()} />}
          </div>
          {/* <div className={classNames(styles.errorContainer, {[styles.error]: !!errors[name]})}>
                <div role="alert" className={styles.errorLabel}>
                  {errors[name]?.message?.toString()}
                </div>
              </div> */}
        </>
      )}
    </div>
  )
}

export default TextInput
