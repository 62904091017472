import {ReactNode} from 'react'

import {ReactComponent as ClientsIcon} from '../../../assets/icons/clients.svg'
// import {ReactComponent as Configuration} from '../../../assets/icons/configuration.svg'
import {ReactComponent as DashboardIcon} from '../../../assets/icons/dashboard.svg'
import {ReactComponent as ReservesIcon} from '../../../assets/icons/flag.svg'
import {ReactComponent as PaymentsIcon} from '../../../assets/icons/payments.svg'
import {Section} from '../../../components/TabBar/TabBar'

import Clients from './Clients/Clients'
import Dashboard from './Dashboard/Dashboard'
import Payments from './Payments/Payments'
import Reserves from './Reserves/Reserves'
// import {ReactComponent as Permutoken} from '../../../assets/icons/refresh.svg'
// import {ReactComponent as Reports} from '../../../assets/icons/reports.svg'

export enum PANEL_SECTION {
  dashboard = 'dashboard',
  clients = 'clients',
  reserves = 'reserves',
  payments = 'payments',
  // permutoken = 'permutoken',
  // reports = 'reports',
  // setting = 'setting',
}

type PanelSection = {
  section: Section
  sectionComponent: ReactNode
}
export const panelSections: PanelSection[] = [
  {
    section: {keyLabel: PANEL_SECTION.dashboard, icon: <DashboardIcon />},
    sectionComponent: <Dashboard />,
  },
  {
    section: {keyLabel: PANEL_SECTION.clients, icon: <ClientsIcon />},
    sectionComponent: <Clients />,
  },
  {
    section: {
      keyLabel: PANEL_SECTION.reserves,
      icon: <ReservesIcon />,
    },
    sectionComponent: <Reserves />,
  },
  {
    section: {keyLabel: PANEL_SECTION.payments, icon: <PaymentsIcon />},
    sectionComponent: <Payments />,
  },
]

export const sections: Section[] = panelSections.map(panelSection => panelSection.section)
