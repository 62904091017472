import {ReactNode, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import OutsideClickHandler from 'react-outside-click-handler'
import classNames from 'classnames'

import {ReactComponent as ArrowDown} from '../../assets/icons/arrow-down-purple.svg'

import styles from './Dropdown.module.scss'

export interface IValue {
  key: string
  label: string
  icon?: ReactNode
}

interface IDropdownProps {
  className?: string
  label?: string
  value: IValue
  setValue: (str: string) => void
  options: Array<IValue>
  isWithImage?: boolean
  name?: string
  placeholder?: string
  underlined?: boolean
  customClasses?: {
    user?: string
    head?: string
    avatar?: string
    selection?: string
    arrow?: string
    body?: string
    option?: string
  }
}

const Dropdown = ({
  className,
  label,
  value,
  setValue,
  options,
  isWithImage,
  name,
  placeholder,
  underlined = false,
  customClasses = {},
}: IDropdownProps) => {
  // const { t } = useTranslation();
  const [visible, setVisible] = useState(false)

  const {
    formState: {errors},
  } = (!!useFormContext() && useFormContext()) || {formState: {}}

  const handleClick = (str: string) => {
    setValue(str)
    setVisible(false)
  }

  const showPlaceholder = placeholder && !value

  return (
    <div className={classNames(styles.container, {[styles.underlined]: underlined})}>
      {!!label && (
        <span
          className={classNames(styles.label, {
            [styles.error]: !!errors && !!errors[name || ''],
          })}>
          {label}
        </span>
      )}
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
        <div
          className={classNames(styles.dropdown, className, {[styles.active]: visible})}
          id={name}>
          <div
            onKeyDown={() => {}}
            tabIndex={0}
            role="button"
            className={classNames(styles.head, customClasses.head, {
              [styles.underlined]: underlined,
              [styles.error]: !!errors && !!errors[name || ''],
            })}
            onClick={() => setVisible(!visible)}>
            {!!value?.icon && (
              <div className={classNames(styles.icon, customClasses.avatar)}>{value?.icon}</div>
            )}
            <div className={styles.selection}>{showPlaceholder ? placeholder : value?.label}</div>
            <div className={classNames(styles.arrow, customClasses.arrow)}>
              <ArrowDown />
            </div>
          </div>
          {!isWithImage ? (
            <div
              className={classNames(styles.bodyContainer, {
                [styles.underlined]: underlined,
              })}>
              <div className={styles.body}>
                {typeof options[0] === 'string'
                  ? options.map(option => (
                      <div
                        onKeyDown={() => {}}
                        tabIndex={0}
                        role="button"
                        className={classNames(styles.option, {
                          [styles.selectioned]: option.label === value?.label,
                        })}
                        onClick={() => handleClick(option.key)}
                        key={option.label}>
                        {option.label}
                      </div>
                    ))
                  : options.map(option => (
                      <div
                        onKeyDown={() => {}}
                        tabIndex={0}
                        role="button"
                        className={classNames(
                          styles.option,
                          {
                            [styles.selectioned]: option.label === value?.label,
                          },
                          option.label === value?.label ? 'text-gradient' : '',
                        )}
                        onClick={() => handleClick(option.key)}
                        key={option.label}>
                        {!!option.icon && <div className={styles.icon}>{option.icon}</div>}
                        <span className={styles.text}>{option.label}</span>
                      </div>
                    ))}
              </div>
            </div>
          ) : (
            <div
              className={classNames(styles.bodyContainer, {
                [styles.underlined]: underlined,
              })}>
              <div className={styles.body}>
                {options.map(option => (
                  <div
                    onKeyDown={() => {}}
                    tabIndex={0}
                    role="button"
                    className={classNames(
                      styles.option,
                      {
                        [styles.selectioned]: option.label === value?.label,
                      },
                      option.label === value?.label ? 'text-gradient' : '',
                    )}
                    onClick={() => handleClick(option.key)}
                    key={option.label}>
                    <div className={classNames(styles.avatar, customClasses.avatar)}>
                      {value?.icon}
                    </div>
                    <span className={styles.text}>{option.label}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default Dropdown
