export const formatDate = (
  value: string,
  locales?: Intl.LocalesArgument,
  options?: Intl.DateTimeFormatOptions,
): string =>
  (!!value &&
    new Date(value).toLocaleDateString(
      locales || 'en-GB',
      options || {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      },
    )) ||
  ''
