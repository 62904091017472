import {useEffect, useMemo} from 'react'
import {Controller, FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as Arrow} from '../../../../../assets/icons/arrow-down.svg'
import Dropdown, {IValue} from '../../../../../components/Dropdown/Dropdown'
import TextInput from '../../../../../components/TextInput/TextInput'
import {FLAG, ICountryValue} from '../../../../../constants/countryFlags'
import {IResponsibleForm} from '../../../../../constants/personalInformationForm'
import * as responsibleForm from '../../../../../constants/personalInformationForm'
import {REG_EX} from '../../../../../constants/regExpression'
import {customerApi} from '../../../../../services'
import {COUNTRY} from '../../../../../services/interfaces/IAddress'
import {useCustomerSlice} from '../../../../../store/slices/customer'

import styles from '../PersonalInformationEditor.module.scss'

interface IForm extends WithTranslation {
  className?: string
  isEditing: boolean
  isSelected: boolean
  select: () => void
  onSubmit: () => void
}
const ResponsibleForm = ({t, isEditing, isSelected, select, onSubmit}: IForm) => {
  const {
    setCustomerData,
    customer_info: {
      id,
      first_name,
      last_name,
      identification_number,
      birth_date,
      email,
      phone_number,
      address,
    },
  } = useCustomerSlice()
  const responsibleMethods = useForm<IResponsibleForm>({
    defaultValues: {
      name: first_name || '',
      surname: last_name || '',
      dni: identification_number || '',
      birthdate: birth_date || '',
      mail: email || '',
      mobile: phone_number || '',
      street: address?.street || '',
      number: address?.number || '',
      floor: address?.floor || '',
      department: address?.department || '',
      cp: address?.cp || '',
      city: address?.city || '',
      province: address?.province || '',
      country: address?.country,
    },
  })

  const countries: ICountryValue[] = useMemo<ICountryValue[]>(
    () =>
      Object.values(COUNTRY).map(value => ({
        key: value,
        label: t(`user.countries.${value.toLocaleLowerCase()}`),
        icon: FLAG[value],
      })),
    [t],
  )

  const onSubmitResponsible = async (data: IResponsibleForm) => {
    try {
      if (!isEditing) return
      const customerInfoResponseData = await customerApi.modifyCustomerInfo(id || 0, {
        first_name: data.name,
        last_name: data.surname,
        identification_number: data.dni,
        birth_date: data.birthdate,
        email: data.mail,
        phone_number: data.mobile.toString(),
        address: {
          street: data.street,
          number: data.number?.toString(),
          floor: data.floor,
          department: data.department,
          cp: data.cp,
          city: data.city,
          province: data.province,
          country: data.country,
        },
      })
      setCustomerData({
        customer_info: customerInfoResponseData,
      })
      onSubmit()
    } catch (error: any) {
      console.log('Error on put data', error)
    }
  }
  const onSubmitResponsibleError = (
    errorData: SubmitErrorHandler<IResponsibleForm> | FieldErrors<IResponsibleForm>,
  ) => {
    console.log('PIForm-ErrorData', errorData)
  }

  useEffect(() => {
    responsibleMethods.reset({
      name: first_name || '',
      surname: last_name || '',
      birthdate: birth_date || '',
      dni: identification_number || '',
      mail: email || '',
      mobile: phone_number || '',
      street: address?.street || '',
      number: address?.number || '',
      floor: address?.floor || '',
      department: address?.department || '',
      cp: address?.cp || '',
      city: address?.city || '',
      province: address?.province || '',
      country: address?.country,
    })
  }, [first_name, last_name, birth_date, identification_number, address, responsibleMethods])

  return (
    <FormProvider {...responsibleMethods}>
      <form
        id="responsible-form"
        onSubmit={responsibleMethods.handleSubmit(onSubmitResponsible, onSubmitResponsibleError)}
        className={classNames(styles.form, {[styles.border]: !isSelected})}>
        <div className={classNames(styles.header, {[styles.selected]: isSelected})}>
          <span className={styles.person}>{t('profile.responsible')}</span>
          <Arrow className={styles.arrow} onClick={() => select()} />
        </div>
        <div className={classNames(styles.body, {[styles.active]: isSelected})}>
          <TextInput
            label={`${t(`user.${responsibleForm.NAME}`)}`}
            name={responsibleForm.NAME}
            withUseForm
            underlined
            required
            disabled={!isEditing}
          />
          <TextInput
            label={`${t(`user.${responsibleForm.SURNAME}`)}`}
            name={responsibleForm.SURNAME}
            withUseForm
            underlined
            required
            disabled={!isEditing}
          />
          <TextInput
            label={`${t(`user.${responsibleForm.DNI}`)}`}
            name={responsibleForm.DNI}
            withUseForm
            underlined
            required
            disabled={!isEditing}
          />
          <TextInput
            label={`${t(`user.${responsibleForm.BIRTHDATE}`)}`}
            name={responsibleForm.BIRTHDATE}
            type="date"
            withUseForm
            underlined
            required
            disabled={!isEditing}
          />
          <TextInput
            label={`${t(`user.${responsibleForm.MAIL}`)}`}
            name={responsibleForm.MAIL}
            pattern={REG_EX.EMAIL}
            withUseForm
            underlined
            required
            disabled={!isEditing}
          />
          <TextInput
            label={`${t(`user.${responsibleForm.MOBILE}`)}`}
            name={responsibleForm.MOBILE}
            placeholder={`${t(`user.${responsibleForm.MOBILE}Placeholder`)}`}
            withUseForm
            underlined
            required
            disabled={!isEditing}
          />
          <div className={styles.row}>
            <TextInput
              className={styles.street}
              label={`${t(`user.${responsibleForm.STREET}`)}`}
              name={responsibleForm.STREET}
              withUseForm
              underlined
              required
            />
            <TextInput
              className={styles.number}
              label={`${t(`user.${responsibleForm.NUMBER}`)}`}
              name={responsibleForm.NUMBER}
              withUseForm
              underlined
              required
              disabled={!isEditing}
            />
            <TextInput
              className={styles.floor}
              label={`${t(`user.${responsibleForm.FLOOR}`)}`}
              name={responsibleForm.FLOOR}
              withUseForm
              underlined
            />
            <TextInput
              className={styles.department}
              label={`${t(`user.${responsibleForm.DEPARTMENT}`)}`}
              name={responsibleForm.DEPARTMENT}
              withUseForm
              underlined
            />
          </div>
          <div className={styles.row}></div>
          <div className={styles.row}>
            <TextInput
              label={`${t(`user.${responsibleForm.CITY}`)}`}
              name={responsibleForm.CITY}
              withUseForm
              underlined
              required
            />
            <TextInput
              label={`${t(`user.${responsibleForm.POSTAL_CODE}`)}`}
              name={responsibleForm.POSTAL_CODE}
              withUseForm
              underlined
              required
            />
          </div>
          <TextInput
            label={`${t(`user.${responsibleForm.PROVINCE}`)}`}
            name={responsibleForm.PROVINCE}
            withUseForm
            underlined
            required
          />
          <Controller
            control={responsibleMethods.control}
            name={responsibleForm.COUNTRY}
            rules={{
              required: true,
            }}
            render={({field: {value, onChange}}) => (
              <Dropdown
                label={`${t(`user.${responsibleForm.COUNTRY}`)}`}
                name={responsibleForm.COUNTRY}
                className={styles.dropdown}
                customClasses={{
                  head: styles.head,
                  arrow: styles.arrow,
                }}
                value={countries.find(country => value === country.key) as IValue}
                setValue={onChange}
                options={countries}
                underlined
              />
            )}
          />

          {/* <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            type="submit"
            loading={submitting}>
            {t('continue')}
          </Button> */}
        </div>
      </form>
    </FormProvider>
  )
}
export default withTranslation()(ResponsibleForm)
