import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'
import {FastAverageColor} from 'fast-average-color'

import {ReactComponent as ArrowDown} from '../../../../assets/icons/arrow-down.svg'
import {ReactComponent as ArrowUp} from '../../../../assets/icons/arrow-up.svg'
import Map from '../../../../components/Map/Map'
import Slider from '../../../../components/Slider/Slider'
import {
  BIM_KEY,
  GALLERY_KEY,
  NavigationButtonKey,
  navigatorButtons,
  PLANS_KEY,
} from '../../../../constants/navigatorButtons'
import {itemImagesSettings} from '../../../../constants/sliderSettings'
import useBreakpoint from '../../../../hooks/useBreakpoint'
import {AssetCategory, IAsset} from '../../../../services/interfaces/IProject'

import styles from './Navigator.module.scss'
interface INavigatorProps extends WithTranslation {
  className?: string
  assets: IAsset[]
  center: {lat: number; lng: number}
  straightCorners?: boolean
}

const Navigator = ({className, assets, center, t, straightCorners}: INavigatorProps) => {
  const [activeButtonKey, setActiveButtonKey] = useState<NavigationButtonKey>(GALLERY_KEY)
  const [mainAsset, setMainAsset] = useState<string>()
  const [filteredAssets, setFilteredAssets] = useState<IAsset[]>([])
  const [averageColor, setAverageColor] = useState<string>('black')
  const breakpoint = useBreakpoint()
  const fac = new FastAverageColor()

  const renderImage = (image: string, slideClass: string) => (
    <>
      {!!filteredAssets?.length ? (
        <img className={slideClass} src={image} onClick={() => setMainAsset(image)} />
      ) : (
        <Skeleton className={slideClass} height="100%" width="100%" />
      )}
    </>
  )

  const onNextSlide = () => (document?.querySelector('.swiper') as any)?.swiper?.slideNext()
  const onPrevSlide = () => (document?.querySelector('.swiper') as any)?.swiper?.slidePrev()

  const NavigatorBtns = useCallback(() => {
    return (
      <div
        className={classNames(styles.navigateButtonsRow, {
          [styles.forceLeft]:
            activeButtonKey !== GALLERY_KEY &&
            activeButtonKey !== BIM_KEY &&
            activeButtonKey !== PLANS_KEY,
        })}>
        {navigatorButtons.map(navigatorButton => (
          <div
            key={navigatorButton.key}
            className={classNames(styles.navigateButton, {
              [styles.active]: navigatorButton.key === activeButtonKey,
              [styles.disabled]:
                navigatorButton?.disabled ||
                (navigatorButton.key === BIM_KEY &&
                  !!assets.length &&
                  !assets.filter(asset => asset?.category?.toLowerCase() === BIM_KEY).length) ||
                (navigatorButton.key === PLANS_KEY &&
                  !!assets.length &&
                  !assets.filter(asset => asset?.category?.toLowerCase() === PLANS_KEY).length),
            })}
            onClick={() => setActiveButtonKey(navigatorButton.key as NavigationButtonKey)}>
            {!!navigatorButton.icon ? (
              navigatorButton.icon
            ) : (
              <span className={styles.iconLabel}>{navigatorButton.iconLabel}</span>
            )}
            {(navigatorButton.labelKey && t(navigatorButton.labelKey)) || navigatorButton?.label}
          </div>
        ))}
      </div>
    )
  }, [navigatorButtons, activeButtonKey, filteredAssets])

  useEffect(() => {
    itemImagesSettings.direction =
      breakpoint !== 's' && breakpoint !== 'xs' ? 'vertical' : 'horizontal'
  }, [breakpoint])

  useEffect(() => {
    if (!mainAsset) return
    fac
      .getColorAsync(mainAsset)
      .then(color => {
        setAverageColor(color.rgb)
      })
      .catch(e => {
        console.log(e)
      })
  }, [mainAsset])

  useEffect(() => {
    if (!!filteredAssets?.length) setMainAsset(filteredAssets[0].src)
  }, [filteredAssets])

  useEffect(() => {
    if (
      (activeButtonKey === BIM_KEY ||
        activeButtonKey === GALLERY_KEY ||
        activeButtonKey === PLANS_KEY) &&
      !!assets.length
    )
      setFilteredAssets(
        assets.filter(
          asset => asset?.category?.toLowerCase() === (activeButtonKey as AssetCategory),
        ) || [],
      )
  }, [activeButtonKey, assets])

  return (
    <div className={styles.mainContainer}>
      <div className={classNames(styles.navigator, className)}>
        {((activeButtonKey === GALLERY_KEY ||
          activeButtonKey === BIM_KEY ||
          activeButtonKey === PLANS_KEY) && (
          <div
            className={classNames(styles.main, styles.desktopImage, {
              [styles.straightCorners]: straightCorners,
            })}>
            {!!filteredAssets?.length && !!mainAsset ? (
              <div
                className={classNames(styles.itemImage, {
                  [styles.straightCorners]: straightCorners,
                })}
                style={{
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.37) 0%, rgba(0, 0, 0, 0.00) 21.15%), url(${mainAsset}) center / contain no-repeat, ${averageColor} 50% / cover no-repeat`,
                }}
              />
            ) : (
              <div
                className={classNames(styles.itemImage, {
                  [styles.straightCorners]: straightCorners,
                })}>
                <Skeleton height="100%" width="100%" />
              </div>
            )}
          </div>
        )) || (
          <div className={styles.main}>
            <Map center={center} />
          </div>
        )}
        <div
          className={classNames(styles.secondary, {
            [styles.visible]:
              activeButtonKey === GALLERY_KEY ||
              activeButtonKey === BIM_KEY ||
              activeButtonKey === PLANS_KEY,
          })}>
          <div className={styles.prevArrow}>
            <ArrowUp className={styles.box} onClick={onPrevSlide} />
          </div>
          <Slider
            slides={
              !!filteredAssets.length
                ? filteredAssets?.map(filteredAsset => filteredAsset.src)
                : Array.from(Array(4).keys())
            }
            className={styles.slider}
            customClasses={{
              swiperSlide: classNames(styles.swiperSlide, {
                [styles.straightCorners]: straightCorners,
              }),
              slide: styles.slide,
            }}
            renderSlide={renderImage}
            settings={itemImagesSettings}
          />
          <div className={styles.nextArrow}>
            <ArrowDown className={styles.box} onClick={onNextSlide} />
          </div>
        </div>
      </div>
      <NavigatorBtns />
    </div>
  )
}

export default withTranslation()(Navigator)
