import {IAddress} from './IAddress'
import {ICharacteristic} from './ICharacteristics'
import {ITokenomic} from './ITokenomic'

export enum BUSINESS_MODEL_TYPE {
  BUY_REMODEL_SELL = 'buy_remodel_sell',
  RENT_CAPITAL_GAIN = 'rent_capital_gain',
  RENT_CAPITAL_GAIN_SELL = 'rent_capital_gain_sell',
  BUILD_TO_SELL = 'build_to_sell',
  REMODEL_RENT = 'remodel_rent',
}
export type BusinessModelType = keyof typeof BUSINESS_MODEL_TYPE

export enum STATUS_TYPE {
  UNDER_REVIEW = 'underReview',
  SEEKING_FUNDING = 'seekingFunding',
  FUNDED = 'funded',
  SOLD = 'sold',
}
export type StatusType = keyof typeof STATUS_TYPE

export enum TYPOLOGY_TYPE {
  RESIDENTIAL = 'residential',
  COMMERCIAL = 'commercial',
  LANDS = 'lands',
  VACATION = 'vacation',
  SHOPPING = 'shopping',
}
export type TypologyType = keyof typeof TYPOLOGY_TYPE

export enum ASSET_CATEGORY {
  GALLERY = 'gallery',
  BIM = 'bim',
  DRONE = 'drone',
  PLANS = 'plans',
}
export type AssetCategory = keyof typeof ASSET_CATEGORY

export interface IAsset {
  id: number
  projects: number
  src: string
  category: AssetCategory
}
export interface IProject {
  id?: number
  name: string
  description: string
  description_en?: string
  developer: string
  architect: string
  marketer: string
  address: IAddress
  in_progress: number
  seeking_funding: boolean
  is_under_review: boolean
  is_funded: boolean
  is_sold: boolean
  is_outstanding: boolean
  is_opportunity: boolean
  status: StatusType
  brand: string
  assets: string[]
  asset_object: IAsset[]
  characteristics: ICharacteristic[]
  tokenomic: ITokenomic
  brochure_url?: string
  agreement_url?: string
  whitepaper_url?: string
  business_model: BusinessModelType
  typology: TypologyType
  contact_phone: string
  development_progress?: number
}
