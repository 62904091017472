import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import {ReactComponent as Empty} from '../../../../assets/icons/empty-state.svg'
import Card from '../../../../components/Card/Card'
import Checkbox from '../../../../components/Checkbox/Checkbox'
import {paymentApi} from '../../../../services'
import {IBlockchainInfo, IPayment} from '../../../../services/api/payment'
import {formatDate} from '../../../../utils/formatDate'
import {formatNumber} from '../../../../utils/formatNumber'
import {PANEL_SECTION} from '../constants'

import PaymentBox from './components/PaymentBox/PaymentBox'
import PaymentDetails from './components/PaymentDetails/PaymentDetails'

import styles from './Payments.module.scss'

enum HEADER_TABLE {
  project = 'project',
  dueDate = 'dueDate',
  amountOwed = 'amountOwed',
}
const headers: (keyof typeof HEADER_TABLE)[] = Object.values(HEADER_TABLE)

const Payments = ({t}: WithTranslation) => {
  const [payments, setPayments] = useState<IPayment[]>()
  const [paymentSelectedForDetail, selectPaymentForDetail] = useState<IPayment | null>()
  const [isFetching, setFetching] = useState<boolean>(false)
  const [isAllSelected, selectAll] = useState<boolean>(false)
  const [subTotal, setSubtotal] = useState<number>(0)
  const [selectedPayments, updateSelectedPayments] = useState<IPayment[]>([])

  const getPayments = useCallback(async () => {
    try {
      setFetching(true)
      const response = await paymentApi.getPaymentsSummary()
      setPayments(response)
    } catch (error) {
      console.log(error)
    } finally {
      setFetching(false)
    }
  }, [])

  const selectPayment = useCallback(
    (payment: IPayment, checked: boolean) => {
      if (checked && !selectedPayments.some(selectedPayment => selectedPayment.id === payment.id)) {
        updateSelectedPayments([...selectedPayments, payment])
        setSubtotal(subTotal + payment.total_projected_payment)
      } else if (!checked) {
        updateSelectedPayments(
          selectedPayments.filter(selectedPayment => selectedPayment.id !== payment.id),
        )
        setSubtotal(subTotal - payment.total_projected_payment)
      }
    },
    [selectedPayments, subTotal, setSubtotal, updateSelectedPayments],
  )

  const selectAllPayments = useCallback(
    (checked: boolean) => {
      if (!payments) return
      if (checked) {
        updateSelectedPayments(payments)
        setSubtotal(
          payments.reduce(
            (accumulator, currentValue) => accumulator + currentValue.total_projected_payment,
            0,
          ),
        )
      } else {
        updateSelectedPayments([])
        setSubtotal(0)
      }
    },
    [isAllSelected, payments, updateSelectedPayments, setSubtotal, subTotal, selectedPayments],
  )

  const refresh = () => {
    getPayments()
    updateSelectedPayments([])
    setSubtotal(0)
  }

  const onPay = useCallback(() => paymentApi.paySummary(selectedPayments), [selectedPayments])

  const onPaymentSuccess = useCallback(
    async (blockchain_info: IBlockchainInfo[]) =>
      await paymentApi.onPaySummarySuccess(blockchain_info, selectedPayments),
    [selectedPayments],
  )

  useEffect(() => {
    getPayments()
  }, [])

  useEffect(() => {
    if (!payments) return
    if (selectedPayments.length === payments.length && !isAllSelected) selectAll(true)
    else if (selectedPayments.length !== payments.length && isAllSelected) selectAll(false)
  }, [selectedPayments, payments])

  return (
    <div className={styles.section}>
      {!!paymentSelectedForDetail ? (
        <PaymentDetails
          payment={paymentSelectedForDetail}
          onPrev={() => {
            getPayments()
            selectPaymentForDetail(null)
          }}
        />
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.textWrapper}>
              <span className={styles.title}>{t('panel.section.payments.title')}</span>
              <span className={styles.subtitle}>{t('panel.section.payments.subtitle')}</span>
            </div>
            <div className={styles.buttons}></div>
          </div>
          <div className={styles.body}>
            <Card className={classNames(styles.card, styles.tableCard)} withShadow>
              <table>
                <tbody>
                  <tr>
                    <th className={styles.check}>
                      <Checkbox
                        checked={isAllSelected}
                        onChange={event => selectAllPayments(event?.target?.checked)}
                      />
                    </th>
                    {headers.map(header => (
                      <th key={header} className={styles[header]}>
                        <span className={styles[header]}>
                          {t(`panel.section.${PANEL_SECTION.payments}.header.${header}`)}
                        </span>
                      </th>
                    ))}
                    <th></th>
                  </tr>
                  {!isFetching &&
                    !!payments?.length &&
                    payments.map((payment: IPayment, index: number) => (
                      <tr
                        key={index}
                        className={styles.itemRow}
                        onClick={event => {
                          if (
                            (event.target as HTMLInputElement)?.localName === 'input' ||
                            (!!(event.target as HTMLInputElement)?.children?.length &&
                              (event.target as HTMLInputElement)?.children[0].localName === 'input')
                          )
                            return
                          selectPaymentForDetail(payment)
                        }}>
                        <td className={styles.check}>
                          <Checkbox
                            checked={selectedPayments.some(
                              selectAllPayment => selectAllPayment.id === payment.id,
                            )}
                            onChange={event => selectPayment(payment, event?.target?.checked)}
                          />
                        </td>
                        <td className={styles.project}>
                          <span>{payment.project_name}</span>
                        </td>
                        <td>
                          <span>{formatDate(payment.due_date)}</span>
                        </td>

                        <td className={styles.amountOwed}>
                          <span>
                            {
                              `$ ${formatNumber(+payment.total_projected_payment)}`
                              // ${(payment.currency.toLocaleUpperCase() === 'CASH' &&
                              //   t('investment.reserve.cash')) ||
                              // payment.currency}`
                            }
                          </span>
                        </td>
                        <td className={styles.actions}>
                          <span onClick={() => selectPaymentForDetail(payment)}>
                            {t('seeDetail')}
                          </span>
                        </td>
                      </tr>
                    ))}
                  {isFetching &&
                    Array.from(Array(5).keys()).map(skeletonRow => (
                      <tr key={skeletonRow}>
                        {Array.from(Array(headers.length + 1).keys()).map(skeleton => (
                          <td
                            key={skeleton}
                            style={{textAlign: (skeleton === 3 && 'end') || 'start'}}>
                            {skeleton !== 0 && <Skeleton width="50%" />}
                          </td>
                        ))}
                      </tr>
                    ))}
                  {!isFetching && !payments?.length && (
                    // <tr className={styles.emptyText}>
                    //   <td>{t('profile.noRecentTransaction')}</td>
                    // </tr>
                    <tr className={styles.emptyState}>
                      <td colSpan={headers.length + 2}>
                        <section>
                          <Empty />
                          <span>{t(`panel.section.${PANEL_SECTION.payments}.noPayments`)}</span>
                        </section>
                      </td>
                    </tr>
                  )}
                </tbody>
                {/* <tfoot>
              {!!payments?.next && (
                <tr className={styles.seeMore}>
                  <td onClick={() => fetchNextPage(payments.next)}>
                    <span>{t('seeMore')}</span> <ArrowDown />
                  </td>
                </tr>
              )}
            </tfoot> */}
              </table>
            </Card>
            <PaymentBox
              className={styles.card}
              paymentsSelected={selectedPayments.length}
              subTotal={subTotal}
              onPay={onPay}
              onPaymentSuccess={onPaymentSuccess}
              onFinishPaymentFlow={refresh}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default withTranslation()(Payments)
