export interface IAddress {
  street: string // "123 Main St"
  city?: string // "Anytown"
  state?: string // "CA"
  cp: string // "12345"
  country?: Country // "Argentina"
  number: string // "1231"
  province?: string // "Buenos Aires"
  one_line?: string
  latitude?: string
  longitude?: string
  floor?: string
  department?: string
}

export enum COUNTRY {
  ARGENTINA = 'ARGENTINA',
  PERU = 'PERU',
  COLOMBIA = 'COLOMBIA',
  CHILE = 'CHILE',
  URUGUAY = 'URUGUAY',
  ECUADOR = 'ECUADOR',
  PANAMA = 'PANAMA',
  SPAIN = 'SPAIN',
}

export type Country = keyof typeof COUNTRY
